@import "styles/color-palette.scss";

body {
    margin: 0;
    padding: 0;
    font-family: 'Work Sans', sans-serif;
    color:$text-primary;
    height:100%;
    font-size: 14.4px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Work Sans', sans-serif;
}

.caption-container {
  padding: 2rem 2rem;
}
.parkfair-container {
  padding: 2rem 2rem;
}

.center-container {
  text-align: center;
  width: 50%;
  margin:auto;
  max-width: 80vw;
}

.blue{color : $primary;}

.benefits img{height:6em;}

.dark-text {
  color: $text-dark;
}
.dark-text a{
 color: $text-dark;
}
.dark-text a:hover{
 color: #7C7C7C;
}

a {
  color: $secondary;
  text-decoration: none;
}
a:hover {
  color: $secondary-darker2;
  text-decoration: none;
}
a.ui {
  text-decoration: none;
}
.delete.icon {
  background: none;
}

.how-it-works img{
  height:18em;
}

.parking-terms img {
  height: 6em;
}

.bigger-text{font-size:120%; }
.box-center{float: left; position: relative; left: 50%;}   /*with .content-left text in ther center, but aligned left*/
.content-left {float: left; position: relative; left: -50%; text-align:left;}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid $primary;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin:auto;
}
.loader-small {
  border: 2px solid #f3f3f3;
  border-top: 2px solid $primary;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin:auto;
}
.loader-small-dark {
  border: 2px solid $text-primary;
  border-top: 2px solid $primary;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin:auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.fake-link {
  cursor:pointer;
  text-decoration:none;
}
.fake-link:hover {
  color:#fff;
  opacity: 0.5
}

div {
  white-space: pre-line;
}

.CookieConsent  {
  z-index: 1300 !important;
}

/*--------------------------FORMS--------------------------*/
input[type=text].y-style, input[type=email].y-style, input[type=password].y-style, input[type=tel].y-style, select.y-style, textarea.y-style, input[type=number].y-style {
    border: 1px solid $primary;
    min-height: 3em;
    color: $text-primary;
    font-family: 'Work Sans', sans-serif;
    border-radius: .25rem;
    margin: 0 auto;
    padding: .375rem .75rem;
    -webkit-box-sizing:border-box;
       -moz-box-sizing:border-box;
            box-sizing:border-box;
    -webkit-appearance: none;      /*remove native browser styling*/
       -moz-appearance: none;
            appearance: none;
}
input.y-style:focus {
  outline-style: none;
}
select.y-style:focus {
  outline-style: none;
}
button:focus {
  outline-style: none;
}

.input-like {
  border: 1px solid $primary !important;
  color: $text-primary !important;
  font-family: 'Work Sans', sans-serif !important;
  border-radius: .25rem !important;
}

.dark-text input[type=text].y-style, .dark-text input[type=email].y-style, .dark-text input[type=password].y-style, .dark-text input[type=tel].y-style, .dark-text select.y-style, .dark-text textarea.y-style {
    border: 1px solid #3C3C3C;
    color: #3C3C3C;
}

textarea {
    height: auto;
}

select.select_arrow{
  /*width:100%;*/
  background-image:url("../assets/icons/drop-down.svg") !important;
  background-repeat: no-repeat !important;
  background-size: auto 50% !important;
  background-position: 97% center !important;
  padding-right:2rem;
}

button[type=button], button[type=submit], a.button {
    font-family: 'Work Sans', sans-serif;
}

.hm-btn {
  width:200px;
  min-height: 3em;
}
.btn-primary.hm-btn {
  width:200px;
  font-weight: 500;
  color:  white;
}
.btn-primary.hm-btn:hover {
  color: white;
}
.btn-outline-primary.hm-btn:hover {
  color:  $text-primary;
}
.btn-outline-light {
  color:  $text-primary;
  border-color: $text-primary;;
}
.btn-outline-light:hover {
  color:  white;
  background-color: $primary;
}

button.btn-link {
  width: auto;
}

.modal-content button[type=button] {
  width: auto;
}

label {
  color: $text-primary;
  text-align:right;
}

.margin{margin: 2%;}
.rdt{padding:0;}      /*datetimepicker class */
.button-img{max-width:25px; margin-left:-7px; padding:3px; background: #fff; border-radius:25px;}

input.switch {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 50px;
  height: 30px;
  background-color: #fff;
  border: 1px solid $primary;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #fff;
}
.dark-text input.switch {
  border-color: #3C3C3C;
}

input.switch:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  background: transparent;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
}

input.switch:checked {
  box-shadow: inset 20px 0 0 0 $primary;
  border-color: $primary;
}
.dark-text input.switch:checked {
  box-shadow: inset 20px 0px 0px 0px #E5E5E5;
  border-color: #3C3C3C;
}

input.switch:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
}
.switch-container{
height: calc(2rem + 2px);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cccccc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #cccccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #cccccc;
}

.error {
   color: red;
   white-space: pre-line;
}

.btn-link:hover {
  text-decoration: none
}

.tooltip-questionmark {
  width: 1.4em;
  height: 1.4em;
  background: $primary;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-left: 2px;
  color: white;
  font-size: 0.7em;
  vertical-align: top;
  padding-left: 1px;
}

/*--------------------------FOOTER--------------------------*/
#footer{
    color: white; background-color: $primary; font-family: 'Work Sans' sans-serif; font-size: 0.82rem;
    border-top: 2px solid #fff;
    width:100%;
    flex-shrink: 0;
}

#footer img{margin:2%; max-height:40px;}

#footer a{color: white; text-decoration: none;}
#footer a:hover {color: #ccc;}

.white-link {color: white; text-decoration: none;}
.white-link:hover {color: #ccc;}

#footer ul{text-align:left; list-style-type: none;  white-space: nowrap;}

.noMargins{margin-bottom:0; padding-bottom:0;}


.cookieConsent  {
  z-index: 1300 !important;
}

img.small{width:25px;}

.store-icon {max-width: 120px;}

.back-button{font-size: 50px;}
.back-button:hover{
  color: $primary;
  cursor: pointer;
}

.collapse-arrow {
  font-size: 1rem;
}

/*--------------------------MEDIA QUERIES--------------------------*/
@media only screen and (max-width: 2200px) {
  .center-container {
    width:65%;
  }
}

@media only screen and (max-width: 1800px) {
  .center-container {
    width:80%;
  }
}

@media only screen and (max-width: 1200px) {
  .center-container {
    width:100%;
  }
}

/*@media only screen and (max-width: 992px) {
}*/

@media only screen and (max-width: 768px) {
  .caption-container {
    padding: 0.5rem 0.5rem;
  }

  label{text-align:center;}
  .hideMobile{display:none;}
  .navbar-brand{width:70%;}
}

@media only screen and (min-width: 576px) and (max-width: 992px) {
  input.switch {width: 35px;height: 20px;}
  input.switch:after {width: 17px; height: 17px;}
  input.switch:checked {box-shadow: inset 15px 0 0 0 $primary;}
}

@media only screen and (min-width: 576px){
  .vertical-align{display: flex; align-items:center;}
}

.sliding-form-block {
    display: flex; /* required */
    flex-flow: row nowrap; /* required */
}
.sliding-form-block .background,
.sliding-form-block .foreground {
    box-sizing: border-box; /* required */
    width: 100%; /* required */
    flex: none; /* required */
}
.sliding-form-block .foreground {
    margin-left: -100%; /* required */
}

.segmented-control {
  border-radius: 0.25rem !important;
  background-color: rgba(255, 255, 255, 0.82);
  color: $primary;
}
.segmented-control > label {
  line-height: calc(2rem + 2px) !important;
}
.segmented-control > label:before {
  background: $primary !important;
}
.segmented-control > label:after {
  color: white !important;
}

#map {
  border-radius: .75rem;
  height: 40vh;
  overflow: hidden;
}

#rides-map {
  border-radius: .75rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: .5rem;

  text-align: center;
}

.map-height-container {
  height: calc(83vh + 1rem);
  position: relative;
}

.rides-map-container {
  height: 100vh;
  position: relative;
}

.map-loader {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  z-index: 6;

  border: 5px solid #fafafa;
  border-top: 5px solid $primary;
  background-color: rgba(255,255,255,0.7);
  box-shadow: 0px 0px 50px rgba(0,0,0,1);
}

/* ----------- SWEET ALERT 2 ---------- */
.swal2-close {
  color: $text-primary !important;
}
.swal2-close:hover {
  color: $primary !important;
}
.swal2-styled {
  min-width: 190px;
}
.swal2-select {
  width: 100%;
}
