@import "styles/color-palette.scss";

.profile-tab{    
    border-radius: .75rem;        
    border: 1px solid $primary;
    padding:1rem;   
}

.profile-tab .btn:disabled {
    opacity: 1;
}

.licence-plate{
    border: solid 0.1em $primary;
    line-height:3em; 
    border-radius: .25rem;
    background-image: url("../assets/icons/eu-plate.svg");
    background-repeat: no-repeat;
    background-size: 2em;
    background-position: 0.25rem center ;    
    padding-left: 3rem;
    margin-bottom:0.5em;
    text-align:left;
}

img.car-brand{height:3em; margin-bottom:0.5em;}

.hm-trash{
    width:35px; height:35px; 
    background:url("../assets/icons/delete.svg") no-repeat;
    background-size:25px;
    background-position:center;
    cursor:pointer;
    margin:auto;
}

@media only screen and (max-width: 576px) {
    .text-right, .text-left{text-align:center!important;}
}