$primary: #33058D;
$secondary: #9DF894;
$text-primary: #353535;
$text-dark: #3C3C3C;
$text-navigation: white;

$primary-lighter2: lighten($primary, 3%);// #43aae5;
$secondary-darker: darken($secondary, 6%);
$primary-darker: darken($primary, 6%);
$secondary-darker2: darken($secondary, 8%);

/* SWAL */
$swal2-confirm-button-background-color: $primary;
$swal2-input-focus-border: 2px solid $primary;
@import "~sweetalert2/src/sweetalert2";

:export {
    colorPrimary: $primary;
    colorSecondary: $secondary;
    textPrimary: $text-primary;
}