@import "styles/color-palette.scss";

#rides {margin:auto; width:80%;}

.ride, .route{
    border: 2px solid $primary;
    border-radius: .75rem;
    padding:1.5%; margin:0;
    color: $text-dark;
}
.ride--canceled {
    color: #93A8B4;
    border-color: #93A8B4;
}
.ride:hover {background:#F8F8F8;}

.ride-cell{
    padding:0.6em;
}
@media only screen and (max-width: 768px) {
    .ride-cell{
        padding:0.3em;
    }
}
.ride-cell img{
    max-width:100%;
}
.roadcar-svg {
    height: 1rem;
}
.place{
    font-weight:600; font-size:120%;
}
.datetime{
    line-height:1rem;
}

.ride-container{position:relative;}
.trash{
    width:35px; height:35px;
    background:$primary url("../assets/icons/delete.svg") no-repeat;
    background-size:25px;
    background-position:center;
    position:absolute; right:-35px; top:10px;
    border-radius: 0 10px 10px 0;
    cursor:pointer;
}

@media only screen and (max-width: 768px) {
    .trash{right:0; top:auto; bottom:50%; border-radius: 10px 0 0 10px; }
}

@media only screen and (max-width: 576px) {
    .offset-4{margin-left: 0;}
    .trash{bottom:35px;}
    .datetime{line-height:1rem;}
}

.delete{
    height: calc(2.25rem + 2px);
    background:$primary url("../assets/icons/delete-white.svg") no-repeat;
    background-size:25px;
    background-position:center;
}
a:hover{text-decoration:none; }

.driver-link {
    color: $text-primary;
}
.driver-link:hover {
    color: $text-primary;
    opacity: 0.5;
}

.segmented-control label {
    margin-bottom: 0;
}

svg .st0{
    fill: currentColor;
}

svg .st1 {
    stroke:currentColor;
}

.map-marker {
    position: absolute;
    width: 24px;
    height: 24px;
    left: -12px;
    top: -12px;
    border-radius: 12px;
    background-color: $primary;
    overflow: hidden;
    cursor: pointer;
    transition: all .2s ease-in-out;
    line-height: 24px;
    color: white;
    font-size: 0.8em;
}

.map-marker-hover {
    z-index: 1000;
    transform: scale(2);
}


.map-marker-image {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 50%;
    height: 50%;
}

.ride-detail-tab{
    color:$text-primary;
    border-radius: .75rem;
    border: solid 2px $primary;
    padding:1rem;
}

.ride-detail-tab .btn:disabled {
    opacity: 1;
}

.rides-block-title {
    text-align: left;
}
.rides-block-title:hover {
    opacity: 0.7;
    cursor: pointer;
}